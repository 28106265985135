// ** Redux Imports
import { combineReducers } from 'redux'
// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import category from  './category/index'
import tags from  './tags/index'
import product from './products/index'
import centeredModal from './centeredModal/index'
import users from './users/index'
import roles from './roles/index'
import news from './news/index'
import pages from './pages/index'
import menus from './menus/index'
import social from './social/index'
import slider from './slider/index'
import textfields from './textfields/index'
 const rootReducer = combineReducers({
  auth,
  users,
  roles,
  navbar,
  layout,
  category,
  tags,
  product,
  centeredModal,
  news,
  pages,
  menus,
  social,
  slider,
  textfields
  })

export default rootReducer
