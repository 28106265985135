// **  Initial State
const initialState = {
    allData: [],
    selected: []
}

const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_ROLES':
            return { ...state, allData: action.data }
        default:
            return state
    }
}

export default roleReducer
