// **  Initial State
const initialState = {
    allData: [],
    selected: null
}

const pagesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PAGES':
            return { ...state, allData: action.data }
        case 'SELECTED_PAGE':
            return { ...state, selected: action.data }
        case 'UPDATED_PAGE':
            return { ...state, selected: state.selected.concat(action.data) }
        case 'DELETE_PAGES':
            return {
                ...state,
                allData: state.allData.filter(news => {return news.id !== action.data.deleteitems} )
            }
        default:
            return state
    }
}

export default pagesReducer
