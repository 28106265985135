// ** React Imports
import { Suspense, lazy } from 'react'
import ReactDOM from 'react-dom'

// ** Redux Imports
import { Provider } from 'react-redux'
import storage from 'redux-persist/lib/storage'
import CryptoJS from 'crypto-js'

import { PersistGate } from 'redux-persist/integration/react'
import {applyMiddleware, createStore} from "redux"
import {persistStore, persistReducer, createTransform} from 'redux-persist'
// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify'
import { ThemeContext } from './utility/context/ThemeColors'

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner'

// ** Ripple Button
import './@core/components/ripple-button'

// ** PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import 'prismjs/components/prism-jsx.min'

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss'

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css'
import './@core/scss/core.scss'
import './assets/scss/style.scss'

// ** Service Worker
import * as serviceWorker from './serviceWorker'
import rootReducer from "./redux/reducers/rootReducer"
import {composeWithDevTools} from "redux-devtools-extension"
import thunk from "redux-thunk"
import {privateKey} from "./config/encyptKey"
// ** Lazy load app
const LazyApp = lazy(() => import('./App'))
const encrypt = createTransform(
    (inboundState, key) => {
        if (!inboundState) return inboundState
        const cryptedText = CryptoJS.AES.encrypt(JSON.stringify(inboundState), privateKey)
        return cryptedText.toString()
    },
    (outboundState, key) => {
        if (!outboundState) return outboundState
        const bytes = CryptoJS.AES.decrypt(outboundState, privateKey)
        const decrypted = bytes.toString(CryptoJS.enc.Utf8)
        return JSON.parse(decrypted)
    }
)
const persistConfig = {
    key: 'root',
    storage,
    transforms: [encrypt]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)))
const persistor = persistStore(store)


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<Spinner />}>
                <ThemeContext>
                    <LazyApp />
                    <ToastContainer newestOnTop />
                </ThemeContext>
            </Suspense>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
