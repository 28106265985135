// **  Initial State
const initialState = {
    productId: null,
    allData: []
}

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PRODUCT_ID':
            return { ...state, productId: action.data.product_id }
        case 'SET_PRODUCTS':
            return { ...state, allData: action.data }
        default:
            return state
    }
}

export default productReducer
