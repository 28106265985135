// **  Initial State
const initialState = {
    allData: [],
    selected: null
}

const newsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_NEWS':
            return { ...state, allData: action.data }
        case 'SELECTED_NEWS':
            return { ...state, selected: action.data }
        case 'DELETE_NEWS':
            return {
                ...state,
                allData: state.allData.filter(news => {return news.id !== action.data.deleteitems} )
            }
        default:
            return state
    }
}

export default newsReducer
