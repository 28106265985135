// **  Initial State
const initialState = {
    allData: [],
    selected: null
}

const menuReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MENUES':
            return { ...state, allData: action.data }
        case 'ADD_MENU':
            return { ...state, allData:[...state.allData.concat(action.data)]  }
        case 'SELECTED_MENU':
            return { ...state, selected: action.data }
        case 'UPDATE_MENU':
            return {
                ...state,
                allData: [
                    ...state.allData.filter(category => category.id !== action.data.id),
                    action.data
                ]
            }
        case 'DELETE_MENU':
            return {
                ...state,
                allData: state.allData.filter(category => {return category.id !== action.data.deleteitems} )
            }
        default:
            return state
    }
}

export default menuReducer
