// **  Initial State
const initialState = {
   modal: false
}

const centeredModalReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MODAL':
            return { ...state, modal: action.date}
        default:
            return state
    }
}

export default centeredModalReducer
